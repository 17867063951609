export const state = () => ({
  activity_types: [],
  tasks: [],
  vitals: [],
  conversations: [],
  education: [],
  reminders: [],
  appointments: [],
  medications: [],
  rcb_data: [],
  alerts: [],
  customTasks: [],
});

export const mutations = {
  SET_ACTIVITY(state, { result, type }) {
    state[type] = result.data;
  },
  ORDER_ACTIVITIES(state, { ids, result, type }) {
    const data = state[type].sort((a, b) => {
      return ids.indexOf(a.id) - ids.indexOf(b.id);
    });

    state[type] = data;
  },
  SET_ACTIVITY_TYPES(state, data) {
    state.activity_types = data;
  },
  SET_RCB_DATA(state, data) {
    state.rcb_data = data;
  },
  SET_ALERTS(state, data) {
    state.alerts = data;
  },
  SET_CUSTOM_TASKS(state, tasks) {
    state.customTasks = tasks;
  },
};

export const actions = {
  async fetchActivityTypes({ state, commit }, { organizationId }) {
    let result = await this.$backend.fetchActivityTypes(organizationId);
    commit("SET_ACTIVITY_TYPES", result.data);
    return result.data;
  },
  async sortActivities(
    { state, commit },
    { organizationId, patientId, payload, type }
  ) {
    let result = await this.$backend.updateOrderForActivity(
      organizationId,
      patientId,
      payload,
      type
    );
    commit("ORDER_ACTIVITIES", { ids: payload, result, type });
    return result.data;
  },
  async fetchActivities(
    { state, commit },
    { organizationId, patientId, type, startDate, endDate, responses }
  ) {
    let result = await this.$backend.fetchActivity(
      organizationId,
      patientId,
      type,
      startDate,
      endDate,
      responses
    );
    commit("SET_ACTIVITY", { result, type });
    return result.data;
  },
  async addSequenceForPatient(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let response = await this.$axios.$post(
      `${organizationId}/patients/${patientId}/activities/sequence`,
      payload
    );
    return response;
  },
  async editSequenceForPatient(
    { state, commit },
    { organizationId, patientId, sequenceId, payload }
  ) {
    let response = await this.$axios.$patch(
      `${organizationId}/patients/${patientId}/activities/sequence/${sequenceId}`,
      payload
    );
    return response;
  },
  async addActivityForPatient(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let result = await this.$backend.addActivityForPatient(
      organizationId,
      patientId,
      payload
    );
    return result.data;
  },
  async addPatientMedication(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let result = await this.$backend.addPatientMedication(
      organizationId,
      patientId,
      payload
    );
    return result.data;
  },
  async updatePatientMedication(
    { state, commit },
    { organizationId, patientId, medicationId, payload }
  ) {
    let result = await this.$backend.updatePatientMedication(
      organizationId,
      patientId,
      medicationId,
      payload
    );
    return result.data;
  },
  async getActivityResponses(
    { state, commit },
    { organizationId, patientId, activity_type, start_date, end_date }
  ) {
    let result = await this.$backend.getActivityResponses(
      organizationId,
      patientId,
      activity_type,
      start_date,
      end_date
    );
    return result.data;
  },
  async updateActivityForPatient(
    { state, commit },
    { organizationId, patientId, payload, activityId }
  ) {
    let response = await this.$backend.updateActivityForPatient(
      organizationId,
      patientId,
      payload,
      activityId
    );
    return response;
  },
  async getCompletedAcitivities(
    { state, commit },
    { organizationId, patientId, start_date }
  ) {
    let response = await this.$backend.getCompletedAcitivities(
      organizationId,
      patientId,
      start_date
    );
    return response;
  },
  async getInompletedAcitivities(
    { state, commit },
    { organizationId, patientId, start_date }
  ) {
    let response = await this.$backend.getInompletedAcitivities(
      organizationId,
      patientId,
      start_date
    );
    return response;
  },
  async getAssignedAcitivities(
    { state, commit },
    { organizationId, patientId, start_date }
  ) {
    let response = await this.$backend.getAssignedAcitivities(
      organizationId,
      patientId,
      start_date
    );
    return response;
  },
  async deletePatientActivity(
    { state, commit },
    { organizationId, patientId, activityId }
  ) {
    let response = await this.$backend.deleteActivity(
      organizationId,
      patientId,
      activityId
    );
    return response;
  },
  async getRCBData({ state, commit }, { organizationId, patientId }) {
    let response = await this.$backend.getRCBData(organizationId, patientId);
    commit("SET_RCB_DATA", response.data);
    return response;
  },
  async getPatientAlerts({ state, commit }, { organizationId, patientId }) {
    let response = await this.$backend.getPatientAlerts(
      organizationId,
      patientId
    );
    return response;
  },
  async getPatientAlertRules(
    { state, commit },
    { organizationId, patientId, currentPage }
  ) {
    let response = await this.$backend.getPatientAlertRules(
      organizationId,
      patientId,
      currentPage
    );
    commit("SET_ALERTS", response.data);
    return response;
  },
  async createPatientAlertRules(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let response = await this.$backend.createPatientAlertRules(
      organizationId,
      patientId,
      payload
    );
    return response;
  },
  async updatePatientAlertRules(
    { state, commit },
    { organizationId, patientId, alertRuleId, payload }
  ) {
    let response = await this.$backend.updatePatientAlertRules(
      organizationId,
      patientId,
      alertRuleId,
      payload
    );
    return response;
  },
  async deletePatientAlertRules(
    { state, commit },
    { organizationId, patientId, alertRuleId }
  ) {
    let response = await this.$backend.deletePatientAlertRules(
      organizationId,
      patientId,
      alertRuleId
    );
    commit(
      "SET_ALERTS",
      state.alerts.filter((alert) => alert.id != alertRuleId)
    );
    return response;
  },
  async getBookActivities({ state, commit }, { organizationId, patientId }) {
    let response = await this.$backend.fetchBooks(organizationId, patientId);
    return response;
  },
  async createBookActivity(
    { state, commit },
    { organizationId, patientId, payload }
  ) {
    let response = await this.$backend.createBookActivity(
      organizationId,
      patientId,
      payload
    );
    return response;
  },
  setCustomTasks({ commit }, tasks) {
    commit('SET_CUSTOM_TASKS', tasks);
  },
};

export const getters = {
  getActivity: (state) => (activity_type) => {
    return state[activity_type];
  },
  getActivityTypes: (state) => () => {
    return state.activity_types
      .map((e) => ({
        activity_type_id: e.id,
        activity_type_slug: e.slug,
        key: e.slug,
        slug: e.slug,
        display_name: e.name,
      }))
      .filter(
        (e) =>
          e.key != "glucose" &&
          e.key != "weight" &&
          e.key != "oxygen_saturation" &&
          e.key != "heart_rate" &&
          e.key != "blood_pressure" &&
          e.key != "appointment" &&
          e.key != "summary" &&
          e.key != "reminders" &&
          e.key != "tasks" &&
          e.key != "education" &&
          e.key != "medications"
      );
  },
  getRCB: (state) => () => {
    return state.rcb_data;
  },
  getAlerts: (state) => () => {
    return state.alerts.map((alert) => ({
      ...alert,
      activity_type: alert.activity_type.localized_name,
    }));
  },
  getCustomTasks: (state) => () => {
    return state.customTasks;
  },
};
