var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"layout-wrapper"}},[_c('b-overlay',{attrs:{"show":_vm.activeNote && _vm.activeNote.state === 'open',"fixed":"","bg-color":"black","opacity":".3","z-index":"1000"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-icon',{attrs:{"icon":"","font-scale":"3","animation":"cylon"}})]},proxy:true}])},[(_vm.$veruFlag('chat') && _vm.$route.name !== 'chats')?_c('chat-manager'):_vm._e(),_vm._v(" "),_vm._v(" "),_c('Topbar',{attrs:{"page":_vm.$route.fullPath.includes('/patients')
            ? 'patients'
            : _vm.$route.name == 'index-name'
            ? 'dashboard'
            : null}}),_vm._v(" "),(
          !_vm.$route.path.includes('care-plan') &&
          !_vm.$route.path.includes('calendar') &&
          _vm.$route.name != 'admin-index-tracks-id'
        )?_c('Sidebar',{attrs:{"type":_vm.leftSidebarType,"width":_vm.layoutWidth,"isMenuCondensed":_vm.isMenuCondensed}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"main-content",style:([
          _vm.$route.name == 'profilea'
            ? { 'margin-left': '0' }
            : _vm.$route.name.includes('chats')
            ? {}
            : {} ])},[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[(
                !_vm.$route.path.includes('calendar') &&
                !_vm.$route.path.includes('care-plan') &&
                !_vm.$route.name === 'users-id-tracks'
              )?_c('transition',{attrs:{"name":"slide-bottom"}},[_c('Nuxt')],1):_c('div',{staticStyle:{"min-height":"80vh"}},[(
                  _vm.$route.name.includes('text') &&
                  _vm.$vianovaConfig.slug === 'oasis'
                )?_c('div',{staticClass:"mb-3",staticStyle:{"background":"white"}},[_c('OasisPatientDetails')],1):_vm._e(),_vm._v(" "),(_vm.activeNote && _vm.activeNote.state === 'expanded')?_c('div',{staticClass:"bg-white",staticStyle:{"border-radius":"12px"}},[_c('div',{staticClass:"bg-light w-100 h-100 d-flex justify-content-center align-items-center"},[_c('expandedNotesModal',{attrs:{"activeNote":_vm.activeNote},on:{"minimizeModal":_vm.minimizeModal,"reopenMinimizedModal":_vm.unexpandModal}})],1)]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  !_vm.activeNote ||
                  (_vm.activeNote && _vm.activeNote.state !== 'expanded')
                ),expression:"\n                  !activeNote ||\n                  (activeNote && activeNote.state !== 'expanded')\n                "}]},[_c('Nuxt')],1)])],1)]),_vm._v(" "),(_vm.activeNote && _vm.activeNote.state === 'minimized')?_c('div',{staticClass:"position-fixed bg-primary p-3",staticStyle:{"bottom":"40px","z-index":"1001","right":"30px","border-radius":"12px"},on:{"click":_vm.reopenMinimizedModal}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex flex-column align-items-start mr-5"},[_c('span',{staticClass:"note_draft"},[_vm._v("You have an ongoing note")]),_vm._v(" "),_c('span',{staticClass:"note_draft_subtitle"},[_vm._v(_vm._s(_vm.activeNote.type !== "internal"
                    ? _vm.activeNote.type.toUpperCase()
                    : "Internal")+"\n                "+_vm._s(("" + (_vm.activeNote.type === "internal" ? "Note" : "Encounter")))+"\n                for\n                "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.activeNote.name))])])]),_vm._v(" "),_c('div',{staticClass:"ml-5"},[_c('draftIcon')],1)])]):_vm._e()])],1),_vm._v(" "),_c('Footer'),_vm._v(" "),_c('RightSidebar')],1),_vm._v(" "),(_vm.activeNote && _vm.activeNote.state == 'open')?_c('div',{staticClass:"position-fixed bg-white",staticStyle:{"right":"30px","bottom":"8px","border-radius":"12px","height":"fit-content","z-index":"1001"}},[_c('div',[_c('note-drawer-modal',{attrs:{"activeNote":_vm.activeNote},on:{"reopenMinimizedModal":function($event){return _vm.reopenMinimizedModal(_vm.activeNote || null)},"expandModal":_vm.expandModal,"minimizeModal":_vm.minimizeModal}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }