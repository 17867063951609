var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"layout-wrapper"}},[(
      !_vm.$route.path.includes('care-plan') &&
      !_vm.$route.path.includes('calendar') &&
      _vm.$route.name != 'admin-index-tracks-id'
    )?_c('Sidebar',{attrs:{"type":_vm.leftSidebarType,"width":_vm.layoutWidth}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"main-content",style:([
      _vm.$route.name == 'profilea'
        ? { 'margin-left': '0' }
        : _vm.$route.name.includes('chats')
        ? {}
        : {} ])},[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('Nuxt')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }