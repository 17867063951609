import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export const layoutComputed = {
  ...mapState("modules/layout", {
    layoutType: (state) => state.layoutType,
    isCondensed: (state) => state.isCondensed,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};

export const layoutMethods = mapActions("modules/layout", [
  "changeLayoutType",
  "changeLayoutWidth",
  "changeLeftSidebarType",
  "changeTopbar",
  "changeLoaderValue",
]);

export const notificationMethods = mapActions("modules/notification", [
  "success",
  "error",
  "clear",
]);

export const organizationsState = {
  ...mapState("modules/organizations", {
    organizations: (state) => state.organizations,
    organization: (state) => state.organization,
  }),
};
export const organizationsMethods = mapActions("modules/organizations", [
  "getOrganizations",
  "getOrganizationDetails",
  "createOrganization",
  "getUserDetails",
]);

export const organizationsGetters = {
  ...mapGetters("modules/organizations", [
    "getOrganizations",
    "getOrganization",
  ]),
};
export const generalChatsGetters = {
  ...mapGetters("general_chat", ["getChatKey"]),
};
export const organizationMutations = {
  ...mapMutations("modules/organizations", ["SET_ORGANIZATION"]),
};

export const configState = {
  ...mapState("modules/config", {
    config: (state) => state.config,
    header: (state) => state.config.user_details.user_header,
    tabs: (state) => state.config.user_details.user_tabs,
  }),
};
export const configMutations = {
  ...mapMutations("modules/config", [
    "SET_LIST_FIELDS",
    "SET_NEW_FIELDS",
    "SET_NEW_CARDS",
    "REARRANGE_TABS",
  ]),
};
export const configGetters = {
  ...mapGetters("modules/config", [
    "getUserTableHeader",
    "getPatientsInfo",
    "getStats",
    "getConfig",
    "getUserTabs",
    "getFields",
    "getCarePlanModalItems",
    "getTabs",
    "getOverviewModalItems",
  ]),
};
export const usersGetters = {
  ...mapGetters("modules/users", [
    "getUserTableHeaders",
    "getUsers",
    "getLeeHeaders",
    "getSearch",
    "getLoadingState",
    "getCurrentTrackerId",
  ]),
};

export const usersMutations = {
  ...mapMutations("modules/users", ["SET_USERS"]),
};
export const vitalsState = {
  ...mapState("modules/vitals", {
    glucose: (state) => state.glucose,
    bloodPressure: (state) => state.bloodPressure,
    healthRate: (state) => state.healthRate,
    weight: (state) => state.weight,
  }),
};

export const vitalsGetters = {
  ...mapGetters("modules/vitals", ["getActivity", "getWeightUnit"]),
};
export const patientNotesGetters = {
  ...mapGetters("modules/patient-notes", ["getNoteType"]),
};
export const vitalsMutations = {
  ...mapMutations("modules/vitals", ["SET_ACTIVITY"]),
};

export const cgmSyncGetters = {
  ...mapGetters("modules/cgm_sync", ["getSyncStatus"]),
};

export const appointmentHystoryGetters = {
  ...mapGetters("modules/appointment-history", ["getAppointmentType"]),
};

export const vitalsActions = {
  ...mapActions("modules/vitals", [
    "fetchActivity",
    "saveActivity",
    "getLabVitalsTypes",
    "getLabVitals",
    "addLabVitals",
    "editLabVital",
    "deleteLabVital",
    "setWeightUnit",
  ]),
};

export const medicState = {
  ...mapState("modules/medications", {
    medics: (state) => state.medics,
  }),
};

export const medicActions = {
  ...mapActions("modules/medications", [
    "fetchMedications",
    "fetchMedicationsDetails",
    "addMedications",
    "deletePatientMedication",
  ]),
};
export const medicGetters = {
  ...mapGetters("modules/medications", [
    "getMedics",
    "getCount",
    "getMedications",
  ]),
};
export const medicMutations = {
  ...mapMutations("modules/medications", ["SET_MEDICS", "INCREMENT"]),
};

export const notesState = {
  ...mapState("modules/notes", {
    overviewNotes: (state) => state.overviewNotes,
    communicationNotes: (state) => state.communicationNotes,
  }),
};

export const advancedFiltersGetters = {
  ...mapGetters("filters", ["getModels", "getAllowedFields", "getFilters"]),
};
export const notesGetters = {
  ...mapGetters("modules/notes", ["getOverviewNotes", "getCommunicationNotes"]),
};

export const assessmentGetters = {
  ...mapGetters("assessments", ["getAssessments"]),
};

export const patientDetailsGetters = {
  ...mapGetters("patient-details", [
    "getPatient",
    "getCoordinator",
    "getLoading",
  ]),
};

export const notesActions = {
  ...mapActions("modules/notes", [
    "fetchNotes",
    "createNote",
    "deleteNote",
    "updateNote",
  ]),
};
export const notesMutations = {
  ...mapMutations("modules/notes", [
    "SET_OVERVIEW_NOTES",
    "SET_COMMUNICATION_NOTES",
  ]),
};
export const alertsGetters = {
  ...mapGetters("modules/alerts", ["getAlerts", "getOrgAlerts"]),
};
export const alertsActions = {
  ...mapActions("modules/alerts", ["fetchAlerts", "fetchOrgAlerts"]),
};
export const alertsMutations = {
  ...mapMutations("modules/alerts", ["SET_ALERTS", "SET_ORG_ALERTS"]),
};
export const communicationState = {
  ...mapState("modules/communication", {
    appointments: (state) => state.appointments,
    chats: (state) => state.chats,
    searchedPatients: (state) => state.chats,
  }),
};
export const communicationGetters = {
  ...mapGetters("modules/communication", ["getChats", "getSearchedPatients"]),
};
export const communicationMutations = {
  ...mapMutations("modules/communication", [
    "SET_APPOINTMENTS",
    "SET_CHATS",
    "SET_ACTIVE_CCM",
  ]),
};

export const messagesState = {
  ...mapState("modules/messages", {
    messages: (state) => state.messages,
  }),
};
export const messagesGetters = {
  ...mapGetters("modules/messages", ["getMessages"]),
};
export const messagesMutations = {
  ...mapMutations("modules/messages", ["SET_MESSAGES"]),
};

export const dashboardState = {
  ...mapState("modules/dashboard", {
    tiles: (state) => state.tiles,
    currentTile: (state) => state.currentList,
    currentCard: (state) => state.currentCard,
  }),
};
export const dashboardGetters = {
  ...mapGetters("modules/dashboard", [
    "getOrgBoards",
    "getTiles",
    "getCurrentTile",
    "getDashboardTableHeader",
    "getCurrentCard",
  ]),
};
export const dashboardMutations = {
  ...mapMutations("modules/dashboard", [
    "SET_TILES",
    "ADD_TO_CURRENT_TILE",
    "SET_CURRENT_CARD",
  ]),
};
export const dashboardActions = {
  ...mapActions("modules/dashboard", [
    "getBoards",
    "createBoard",
    "updateBoard",
    "deleteBoard",
    "getBoardsTiles",
    "createBoardTile",
    "updateBoardTile",
    "deleteBoardTile",
    "getQuery",
  ]),
};
export const carePlanGetters = {
  ...mapGetters("modules/care-plan", ["getTasks", "getMedications"]),
};
export const carePlanActions = {
  ...mapActions("modules/care-plan", [
    "createTask",
    "updateTask",
    "deleteTask",
    "createMedication",
  ]),
};

export const appointmentsMutation = {
  ...mapMutations("modules/appointments", [
    "SET_APPOINTMENTS",
    "SET_ORG_APPOINTMENTS",
  ]),
};

export const appointmentsActions = {
  ...mapActions("modules/appointments", [
    "fetchAppointments",
    "fetchOrgAppointments",
    "addAppointment",
    "updateAppointment",
    "deleteAppointment",
  ]),
};

export const activitiesGetters = {
  ...mapGetters("modules/care_plan_activities", [
    "getActivity",
    "getActivityTypes",
    "getRCB",
    "getAlerts",
  ]),
};

export const trackerGetters = {
  ...mapGetters("trackers", [
    "getCallState",
    "getCurrentTracker",
    "getTrackers",
    "editTimer",
    "inactivityReached",
    "getStartedTimer",
    "getSelectedDate",
  ]),
};
export const activitiesMutation = {
  ...mapMutations("modules/care_plan_activities", [
    "SET_ACTIVITY",
    "SET_ACTIVITY_TYPES",
  ]),
};

export const paymentActions = {
  ...mapActions("modules/payments", [
    "fetchPaymentIntent",
    "fetchPaymentMethods",
    "addPaymentMethod",
    "addDefaultPaymentMethod",
    "deletePaymentMethod",
    "createCharge",
  ]),
};

export const activitiesActions = {
  ...mapActions("modules/care_plan_activities", [
    "sortActivities",
    "fetchActivityTypes",
    "fetchActivities",
    "addActivityForPatient",
    "getActivityResponses",
    "updateActivityForPatient",
    "deletePatientActivity",
    "getCompletedAcitivities",
    "getInompletedAcitivities",
    "getAssignedAcitivities",
    "addPatientMedication",
    "getRCBData",
    "updatePatientMedication",
    "getPatientAlerts",
    "getPatientAlertRules",
    "createPatientAlertRules",
    "updatePatientAlertRules",
    "deletePatientAlertRules",
    "createBookActivity",
  ]),
};

export const adminActions = {
  ...mapActions("modules/admin", [
    "getUserApplicableOrganizations",
    "fetchOrganizations",
    "fetchOrganizationsList",
    "deleteOrganization",
    "fetchRoles",
    "getOrganizationDetails",
    "createOrganization",
    "updateOrganization",
    "getOrganizationSettings",
    "updateOrganizationSettings",
    //
    "fetchUsers",
    "getUser",
    "createUser",
    "updateUser",
    "deleteUser",
    //
    "getSpecialities",
    "getSpecialists",
    "getSpecialist",
    "createSpecialist",
    "updateSpecialist",
    "deleteSpecialist",
    //
    "getSpecialities",
    "getSpecialists",
    "getSpecialist",
    "createSpecialist",
    "updateSpecialist",
    "deleteAdminSpecialist",
    //
    "fetchProviders",
    "getProvider",
    "createProvider",
    "updateProvider",
    "deleteProvider",
    //
    "getAdminActivityTypes",
    "getAdminMeasurementsDurations",
    "getAdminMeasurementDuration",
    "createAdminMeasurementDuration",
    "updateAdminMeasurementDuration",
    "deleteAdminMeasurementDuration",
    //
    "fetchDiseases",
    "getDisease",
    "createDisease",
    "updateDisease",
    "deleteDisease",
    //
    "getDiseasePlan",
    "createDiseasePlan",
    "updateDiseasePlan",
    "deleteDiseasePlan",
    //
    //
    "getDiseaseRule",
    "createDiseaseRule",
    "updateDiseaseRule",
    "deleteDiseaseRule",
    //
    "getAdminMedications",
    "getAdminMedication",
    "createAdminMedication",
    "updateAdminMedication",
    "deleteAdminMedication",
    //
    "updateBook",
    "createBook",
  ]),
};

export const adminGetters = {
  ...mapGetters("modules/admin", ["getSearch", "getAdminDiseases"]),
};

export const chatsActions = {
  ...mapActions("modules/chats", [
    "fetchChatPatients",
    "fetchPatientConversation",
    "startNewConverssation",
    "searchedPatientsWithInbox",
  ]),
};
export const chatsGetters = {
  ...mapGetters("modules/chats", [
    "getLoadingChats",
    "getChatPatients",
    "getCurrentPatientConversation",
    "getSearchedPatients",
  ]),
};
export const amdiabetesFiltersGetters = {
  ...mapGetters("modules/am_patient_filters", [
    "getFilters",
    "getLoader",
    "getAddedNote",
  ]),
};

export const cannaFilterGetters = {
  ...mapGetters("modules/cannaPatientFilters", ["getFilters"]),
};
export const twoFactorAuthenticationGetters = {
  ...mapGetters("modules/two_factor_authentication", [
    "getAuthUser",
    "getAuthState",
  ]),
};

export const amdiabetesHeadersGetters = {
  ...mapGetters("modules/patient_list_headers", ["getHeaders"]),
};

export const cannaBookingGetters = {
  ...mapGetters("modules/bookAppointmentSteps", [
    "getFirstSteps",
    "getThirdSteps",
    "getFourthSteps",
    "getSecondSteps",
    "getCouponData",
  ]),
};

export const amdiabetesReportsGetters = {
  ...mapGetters("modules/rpm-reports", ["getAllData", "getVitalRanges"]),
};

export const mergePatientsGetters = {
  ...mapGetters("modules/merge_patient", [
    "getSourcePatient",
    "getDestinationPatient",
  ]),
};

export const oasisFiltersGetters = {
  ...mapGetters("oasis_patient_filters", [
    "getFilters",
    "getCurrentPage",
    "getCurrentRoute",
  ]),
};

export const oasisAssessmentGetters = {
  ...mapGetters("oasis_assessment", ["getAvailableSteps"]),
};

export const amdiabetesSearchGetters = {
  ...mapGetters("modules/amdiabetes-search", ["getSearch", "getPatientSearch"]),
};
export const amdiabetesPatientGetters = {
  ...mapGetters("modules/onboarding_amdiabetes", ["getPatient"]),
};

export const chatsMutations = {
  ...mapMutations("modules/chats", ["SET_PATIENTS"]),
};

export const searchGetters = {
  ...mapGetters("modules/search", ["getSearch"]),
};

export const organizationAppointmentGetters = {
  ...mapGetters("organization_appointments", ["getAppointmentType"]),
};
