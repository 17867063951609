export const state = () => ({
  userGroups: [],
  selectedCohort: null,
  loading: false
});

export const mutations = {
  SET_USER_GROUPS(state, groups) {
    state.userGroups = groups;
  },
  SET_SELECTED_COHORT(state, cohort) {
    state.selectedCohort = cohort;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  }
};

export const actions = {
  setUserGroups({ commit }, groups) {
    commit('SET_USER_GROUPS', groups);
  },
  selectCohort({ commit }, cohort) {
    commit('SET_SELECTED_COHORT', cohort);
  },
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading);
  }
};

export const getters = {
  getUserGroups: (state) => () => {
    return state.userGroups.filter(group => group.status === 'active');
  },
  getHistoricalGroups: (state) => () => {
    return state.userGroups.filter(group => group.status === 'inactive');
  },
  getSelectedCohort: (state) => () => {
    return state.selectedCohort;
  },
  isLoading: (state) => () => {
    return state.loading;
  }
};
