import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _25702d7e = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _5906a1d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _4f8585e4 = () => interopDefault(import('../pages/admin/index/admin_users/index.vue' /* webpackChunkName: "pages/admin/index/admin_users/index" */))
const _cb0cff08 = () => interopDefault(import('../pages/admin/index/apps/index.vue' /* webpackChunkName: "pages/admin/index/apps/index" */))
const _18de23f0 = () => interopDefault(import('../pages/admin/index/dispensaries/index.vue' /* webpackChunkName: "pages/admin/index/dispensaries/index" */))
const _49779d9e = () => interopDefault(import('../pages/admin/index/groups/index.vue' /* webpackChunkName: "pages/admin/index/groups/index" */))
const _4cd193f1 = () => interopDefault(import('../pages/admin/index/import_patients.vue' /* webpackChunkName: "pages/admin/index/import_patients" */))
const _c750b736 = () => interopDefault(import('../pages/admin/index/measurement_durations.vue' /* webpackChunkName: "pages/admin/index/measurement_durations" */))
const _e6d39896 = () => interopDefault(import('../pages/admin/index/onboard_medications.vue' /* webpackChunkName: "pages/admin/index/onboard_medications" */))
const _79dbdfa8 = () => interopDefault(import('../pages/admin/index/organizations/index.vue' /* webpackChunkName: "pages/admin/index/organizations/index" */))
const _7e1aa5c0 = () => interopDefault(import('../pages/admin/index/payment_coupons/index.vue' /* webpackChunkName: "pages/admin/index/payment_coupons/index" */))
const _1b2b5374 = () => interopDefault(import('../pages/admin/index/provider_compensation/index.vue' /* webpackChunkName: "pages/admin/index/provider_compensation/index" */))
const _76b6d485 = () => interopDefault(import('../pages/admin/index/referal_coupons/index.vue' /* webpackChunkName: "pages/admin/index/referal_coupons/index" */))
const _f092fd20 = () => interopDefault(import('../pages/admin/index/rewards/index.vue' /* webpackChunkName: "pages/admin/index/rewards/index" */))
const _10d807f4 = () => interopDefault(import('../pages/admin/index/roles.vue' /* webpackChunkName: "pages/admin/index/roles" */))
const _28060030 = () => interopDefault(import('../pages/admin/index/services/index.vue' /* webpackChunkName: "pages/admin/index/services/index" */))
const _2539df6b = () => interopDefault(import('../pages/admin/index/specialists.vue' /* webpackChunkName: "pages/admin/index/specialists" */))
const _1a829f68 = () => interopDefault(import('../pages/admin/index/states/index.vue' /* webpackChunkName: "pages/admin/index/states/index" */))
const _78102b52 = () => interopDefault(import('../pages/admin/index/tracks/index.vue' /* webpackChunkName: "pages/admin/index/tracks/index" */))
const _24528394 = () => interopDefault(import('../pages/admin/index/users/index.vue' /* webpackChunkName: "pages/admin/index/users/index" */))
const _679be098 = () => interopDefault(import('../pages/admin/index/website_hours.vue' /* webpackChunkName: "pages/admin/index/website_hours" */))
const _fe99333a = () => interopDefault(import('../pages/admin/index/documents/shared.vue' /* webpackChunkName: "pages/admin/index/documents/shared" */))
const _0d3060e0 = () => interopDefault(import('../pages/admin/index/groups/create.vue' /* webpackChunkName: "pages/admin/index/groups/create" */))
const _1c88e4c2 = () => interopDefault(import('../pages/admin/index/library/topics/index.vue' /* webpackChunkName: "pages/admin/index/library/topics/index" */))
const _6082091e = () => interopDefault(import('../pages/admin/index/tracks/alert/_alertId/index.vue' /* webpackChunkName: "pages/admin/index/tracks/alert/_alertId/index" */))
const _e9997f96 = () => interopDefault(import('../pages/admin/index/library/topics/_id/articles/index.vue' /* webpackChunkName: "pages/admin/index/library/topics/_id/articles/index" */))
const _4c268cee = () => interopDefault(import('../pages/admin/index/groups/_id/index.vue' /* webpackChunkName: "pages/admin/index/groups/_id/index" */))
const _6d416586 = () => interopDefault(import('../pages/admin/index/tracks/_id/index.vue' /* webpackChunkName: "pages/admin/index/tracks/_id/index" */))
const _0799212c = () => interopDefault(import('../pages/admin/index/users/_userId/index.vue' /* webpackChunkName: "pages/admin/index/users/_userId/index" */))
const _41fd5d05 = () => interopDefault(import('../pages/admin/index/apps/_id/edit.vue' /* webpackChunkName: "pages/admin/index/apps/_id/edit" */))
const _7c03f76e = () => interopDefault(import('../pages/admin/index/organizations/_id/settings.vue' /* webpackChunkName: "pages/admin/index/organizations/_id/settings" */))
const _0e06e978 = () => interopDefault(import('../pages/alerts/index.vue' /* webpackChunkName: "pages/alerts/index" */))
const _eb8ef848 = () => interopDefault(import('../pages/amdiabetes/index.vue' /* webpackChunkName: "pages/amdiabetes/index" */))
const _45e13ba1 = () => interopDefault(import('../pages/appointments/index.vue' /* webpackChunkName: "pages/appointments/index" */))
const _5b12e78c = () => interopDefault(import('../pages/cannahealrx/index.vue' /* webpackChunkName: "pages/cannahealrx/index" */))
const _1d9ff4e4 = () => interopDefault(import('../pages/chats/index.vue' /* webpackChunkName: "pages/chats/index" */))
const _f476fb62 = () => interopDefault(import('../pages/civiclee/index.vue' /* webpackChunkName: "pages/civiclee/index" */))
const _50d99d1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _69d32841 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _e7419d08 = () => interopDefault(import('../pages/general_chat/index.vue' /* webpackChunkName: "pages/general_chat/index" */))
const _b702abc6 = () => interopDefault(import('../pages/goodcannanow/index.vue' /* webpackChunkName: "pages/goodcannanow/index" */))
const _7f68f1e5 = () => interopDefault(import('../pages/inventory/index.vue' /* webpackChunkName: "pages/inventory/index" */))
const _787257f2 = () => interopDefault(import('../pages/magic-link/index.vue' /* webpackChunkName: "pages/magic-link/index" */))
const _32a91b84 = () => interopDefault(import('../pages/meeting/index.vue' /* webpackChunkName: "pages/meeting/index" */))
const _d12672d8 = () => interopDefault(import('../pages/oasis/index.vue' /* webpackChunkName: "pages/oasis/index" */))
const _b6b2606e = () => interopDefault(import('../pages/organization_appointments/index.vue' /* webpackChunkName: "pages/organization_appointments/index" */))
const _5cdd46e3 = () => interopDefault(import('../pages/overview.vue' /* webpackChunkName: "pages/overview" */))
const _3b7c748a = () => interopDefault(import('../pages/patient_filters/index.vue' /* webpackChunkName: "pages/patient_filters/index" */))
const _7166697b = () => interopDefault(import('../pages/patients/index.vue' /* webpackChunkName: "pages/patients/index" */))
const _ef592d1c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _49c3b1ab = () => interopDefault(import('../pages/providers/index.vue' /* webpackChunkName: "pages/providers/index" */))
const _1af3004e = () => interopDefault(import('../pages/providers/index/_id/index.vue' /* webpackChunkName: "pages/providers/index/_id/index" */))
const _3cf248d8 = () => interopDefault(import('../pages/readings/index.vue' /* webpackChunkName: "pages/readings/index" */))
const _d585e6ec = () => interopDefault(import('../pages/reminders/index.vue' /* webpackChunkName: "pages/reminders/index" */))
const _76dee3f0 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _58bd2e4c = () => interopDefault(import('../pages/reports/index/conversation_reports.vue' /* webpackChunkName: "pages/reports/index/conversation_reports" */))
const _4cd4d019 = () => interopDefault(import('../pages/reports/index/multiple_patients/index.vue' /* webpackChunkName: "pages/reports/index/multiple_patients/index" */))
const _d82fd3d2 = () => interopDefault(import('../pages/reports/index/multiple_patients/index/ccm.vue' /* webpackChunkName: "pages/reports/index/multiple_patients/index/ccm" */))
const _78891f1c = () => interopDefault(import('../pages/reports/index/multiple_patients/index/general.vue' /* webpackChunkName: "pages/reports/index/multiple_patients/index/general" */))
const _d1f839c4 = () => interopDefault(import('../pages/reports/index/single_patients.vue' /* webpackChunkName: "pages/reports/index/single_patients" */))
const _a159c01a = () => interopDefault(import('../pages/team_monitoring/index.vue' /* webpackChunkName: "pages/team_monitoring/index" */))
const _148037ab = () => interopDefault(import('../pages/testPage.vue' /* webpackChunkName: "pages/testPage" */))
const _b1616888 = () => interopDefault(import('../pages/auth/forbidden.vue' /* webpackChunkName: "pages/auth/forbidden" */))
const _2fd260f0 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _1570f4ac = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _3482ad52 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _3bcc56a0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _04e0c59a = () => interopDefault(import('../pages/auth/success-fitbit.vue' /* webpackChunkName: "pages/auth/success-fitbit" */))
const _93d1f99c = () => interopDefault(import('../pages/auth/two_factor/index.vue' /* webpackChunkName: "pages/auth/two_factor/index" */))
const _c9478508 = () => interopDefault(import('../pages/cannahealrx/appointments.vue' /* webpackChunkName: "pages/cannahealrx/appointments" */))
const _57072c02 = () => interopDefault(import('../pages/cannahealrx/approved.vue' /* webpackChunkName: "pages/cannahealrx/approved" */))
const _eff5259e = () => interopDefault(import('../pages/cannahealrx/book.vue' /* webpackChunkName: "pages/cannahealrx/book" */))
const _120a1c41 = () => interopDefault(import('../pages/cannahealrx/booking.vue' /* webpackChunkName: "pages/cannahealrx/booking" */))
const _5595ecba = () => interopDefault(import('../pages/cannahealrx/no_show.vue' /* webpackChunkName: "pages/cannahealrx/no_show" */))
const _e4aa1454 = () => interopDefault(import('../pages/cannahealrx/patients.vue' /* webpackChunkName: "pages/cannahealrx/patients" */))
const _67e7dc46 = () => interopDefault(import('../pages/cannahealrx/rejected.vue' /* webpackChunkName: "pages/cannahealrx/rejected" */))
const _6e8e8e08 = () => interopDefault(import('../pages/civiclee/indexOld.vue' /* webpackChunkName: "pages/civiclee/indexOld" */))
const _333103d4 = () => interopDefault(import('../pages/civiclee/landing.vue' /* webpackChunkName: "pages/civiclee/landing" */))
const _1c6569f2 = () => interopDefault(import('../pages/civiclee/landinggpt.vue' /* webpackChunkName: "pages/civiclee/landinggpt" */))
const _16fa3b3a = () => interopDefault(import('../pages/civiclee/landingOld.vue' /* webpackChunkName: "pages/civiclee/landingOld" */))
const _0f34e04e = () => interopDefault(import('../pages/civiclee/members/index.vue' /* webpackChunkName: "pages/civiclee/members/index" */))
const _cb47ab24 = () => interopDefault(import('../pages/civiclee/onboarding.vue' /* webpackChunkName: "pages/civiclee/onboarding" */))
const _6c159880 = () => interopDefault(import('../pages/civiclee/success.vue' /* webpackChunkName: "pages/civiclee/success" */))
const _65297571 = () => interopDefault(import('../pages/dashboard/all_patients.vue' /* webpackChunkName: "pages/dashboard/all_patients" */))
const _38154e2a = () => interopDefault(import('../pages/dashboard/call_list.vue' /* webpackChunkName: "pages/dashboard/call_list" */))
const _de026468 = () => interopDefault(import('../pages/dashboard/my_patients.vue' /* webpackChunkName: "pages/dashboard/my_patients" */))
const _31eda060 = () => interopDefault(import('../pages/dashboard/onboarding.vue' /* webpackChunkName: "pages/dashboard/onboarding" */))
const _2da3aeb7 = () => interopDefault(import('../pages/dental/appointments.vue' /* webpackChunkName: "pages/dental/appointments" */))
const _18931a70 = () => interopDefault(import('../pages/dental/library_de.vue' /* webpackChunkName: "pages/dental/library_de" */))
const _142bc220 = () => interopDefault(import('../pages/dental/library_en.vue' /* webpackChunkName: "pages/dental/library_en" */))
const _1fe31f5e = () => interopDefault(import('../pages/dental/onboarding.vue' /* webpackChunkName: "pages/dental/onboarding" */))
const _3f92cf81 = () => interopDefault(import('../pages/halee/landing.vue' /* webpackChunkName: "pages/halee/landing" */))
const _354ff7fe = () => interopDefault(import('../pages/inventory/upload.vue' /* webpackChunkName: "pages/inventory/upload" */))
const _528204cc = () => interopDefault(import('../pages/magic-link/expired.vue' /* webpackChunkName: "pages/magic-link/expired" */))
const _2cbe87dc = () => interopDefault(import('../pages/monitoring/rpm.vue' /* webpackChunkName: "pages/monitoring/rpm" */))
const _7d12081a = () => interopDefault(import('../pages/oasis/all_patients.vue' /* webpackChunkName: "pages/oasis/all_patients" */))
const _58567bfa = () => interopDefault(import('../pages/oasis/my_patients.vue' /* webpackChunkName: "pages/oasis/my_patients" */))
const _5e066d6e = () => interopDefault(import('../pages/oasis/onboarding.vue' /* webpackChunkName: "pages/oasis/onboarding" */))
const _8981c1f4 = () => interopDefault(import('../pages/oasis/review.vue' /* webpackChunkName: "pages/oasis/review" */))
const _7898dc1c = () => interopDefault(import('../pages/reports/loading.vue' /* webpackChunkName: "pages/reports/loading" */))
const _2fb96a45 = () => interopDefault(import('../pages/auth/patient/delete/index.vue' /* webpackChunkName: "pages/auth/patient/delete/index" */))
const _6247ea08 = () => interopDefault(import('../pages/charts/apex/data.js' /* webpackChunkName: "pages/charts/apex/data" */))
const _a8504c38 = () => interopDefault(import('../pages/charts/chartjs/DonutChart.vue' /* webpackChunkName: "pages/charts/chartjs/DonutChart" */))
const _6b601394 = () => interopDefault(import('../pages/auth/patient/delete/success.vue' /* webpackChunkName: "pages/auth/patient/delete/success" */))
const _2be40686 = () => interopDefault(import('../pages/auth/patient/delete/_identifier/verify.vue' /* webpackChunkName: "pages/auth/patient/delete/_identifier/verify" */))
const _55441786 = () => interopDefault(import('../pages/auth/reset/_id.vue' /* webpackChunkName: "pages/auth/reset/_id" */))
const _acdfabcc = () => interopDefault(import('../pages/auth/two_factor/_id.vue' /* webpackChunkName: "pages/auth/two_factor/_id" */))
const _3e363b9a = () => interopDefault(import('../pages/civiclee/users/_id/index.vue' /* webpackChunkName: "pages/civiclee/users/_id/index" */))
const _1ed90c12 = () => interopDefault(import('../pages/dashboard/merge_patient/_id/index.vue' /* webpackChunkName: "pages/dashboard/merge_patient/_id/index" */))
const _164f03ab = () => interopDefault(import('../pages/civiclee/users/_id/overview/index.vue' /* webpackChunkName: "pages/civiclee/users/_id/overview/index" */))
const _4caf8626 = () => interopDefault(import('../pages/dashboard/merge_patient/_id/_cid/index.vue' /* webpackChunkName: "pages/dashboard/merge_patient/_id/_cid/index" */))
const _eefc3ad8 = () => interopDefault(import('../pages/amdiabetes/_slug.vue' /* webpackChunkName: "pages/amdiabetes/_slug" */))
const _4d8bc4f9 = () => interopDefault(import('../pages/cannahealrx/_status.vue' /* webpackChunkName: "pages/cannahealrx/_status" */))
const _74dcf5fe = () => interopDefault(import('../pages/magic-link/_code.vue' /* webpackChunkName: "pages/magic-link/_code" */))
const _9e7e5722 = () => interopDefault(import('../pages/meeting/_id/index.vue' /* webpackChunkName: "pages/meeting/_id/index" */))
const _28fdb9c0 = () => interopDefault(import('../pages/monitoring/_id/index.vue' /* webpackChunkName: "pages/monitoring/_id/index" */))
const _0ea587b3 = () => interopDefault(import('../pages/onboarding/_id/index.vue' /* webpackChunkName: "pages/onboarding/_id/index" */))
const _4b961b18 = () => interopDefault(import('../pages/onboarding/_id/index/address.vue' /* webpackChunkName: "pages/onboarding/_id/index/address" */))
const _74208e3b = () => interopDefault(import('../pages/onboarding/_id/index/care_plan.vue' /* webpackChunkName: "pages/onboarding/_id/index/care_plan" */))
const _5bbb4ffa = () => interopDefault(import('../pages/onboarding/_id/index/care_provider.vue' /* webpackChunkName: "pages/onboarding/_id/index/care_provider" */))
const _bfc4a578 = () => interopDefault(import('../pages/onboarding/_id/index/contact.vue' /* webpackChunkName: "pages/onboarding/_id/index/contact" */))
const _bf646004 = () => interopDefault(import('../pages/onboarding/_id/index/insurance.vue' /* webpackChunkName: "pages/onboarding/_id/index/insurance" */))
const _67e4bd16 = () => interopDefault(import('../pages/onboarding/_id/index/patient_information.vue' /* webpackChunkName: "pages/onboarding/_id/index/patient_information" */))
const _4998b0f4 = () => interopDefault(import('../pages/onboarding/_id/index/specialists.vue' /* webpackChunkName: "pages/onboarding/_id/index/specialists" */))
const _74e1d0b2 = () => interopDefault(import('../pages/onboarding/_id/index/tasks.vue' /* webpackChunkName: "pages/onboarding/_id/index/tasks" */))
const _02d78b16 = () => interopDefault(import('../pages/patient_filters/_id/index.vue' /* webpackChunkName: "pages/patient_filters/_id/index" */))
const _4477091b = () => interopDefault(import('../pages/pg/_token/index.vue' /* webpackChunkName: "pages/pg/_token/index" */))
const _60d03d7c = () => interopDefault(import('../pages/readings/_rid.vue' /* webpackChunkName: "pages/readings/_rid" */))
const _7602f9ca = () => interopDefault(import('../pages/team_monitoring/_id.vue' /* webpackChunkName: "pages/team_monitoring/_id" */))
const _e0a15e08 = () => interopDefault(import('../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))
const _5e954257 = () => interopDefault(import('../pages/meeting/_id/ended.vue' /* webpackChunkName: "pages/meeting/_id/ended" */))
const _513a3fdc = () => interopDefault(import('../pages/profile/_id/schedule.vue' /* webpackChunkName: "pages/profile/_id/schedule" */))
const _34ae2fed = () => interopDefault(import('../pages/users/_id/achievements/index.vue' /* webpackChunkName: "pages/users/_id/achievements/index" */))
const _6e8d2220 = () => interopDefault(import('../pages/users/_id/alerts/index.vue' /* webpackChunkName: "pages/users/_id/alerts/index" */))
const _a7f78506 = () => interopDefault(import('../pages/users/_id/appointments/index.vue' /* webpackChunkName: "pages/users/_id/appointments/index" */))
const _a1868b46 = () => interopDefault(import('../pages/users/_id/attachments/index.vue' /* webpackChunkName: "pages/users/_id/attachments/index" */))
const _23bac306 = () => interopDefault(import('../pages/users/_id/attachments/index/documents.vue' /* webpackChunkName: "pages/users/_id/attachments/index/documents" */))
const _3393ab1a = () => interopDefault(import('../pages/users/_id/attachments/index/images.vue' /* webpackChunkName: "pages/users/_id/attachments/index/images" */))
const _292433fa = () => interopDefault(import('../pages/users/_id/attachments/index/videos.vue' /* webpackChunkName: "pages/users/_id/attachments/index/videos" */))
const _426ca50e = () => interopDefault(import('../pages/users/_id/badges/index.vue' /* webpackChunkName: "pages/users/_id/badges/index" */))
const _75844244 = () => interopDefault(import('../pages/users/_id/cannahealrx/index.vue' /* webpackChunkName: "pages/users/_id/cannahealrx/index" */))
const _1932aafa = () => interopDefault(import('../pages/users/_id/communication/index.vue' /* webpackChunkName: "pages/users/_id/communication/index" */))
const _f4c04534 = () => interopDefault(import('../pages/users/_id/communications/index.vue' /* webpackChunkName: "pages/users/_id/communications/index" */))
const _1d6879d6 = () => interopDefault(import('../pages/users/_id/communications/index/phone-call.vue' /* webpackChunkName: "pages/users/_id/communications/index/phone-call" */))
const _e0573f34 = () => interopDefault(import('../pages/users/_id/communications/index/text.vue' /* webpackChunkName: "pages/users/_id/communications/index/text" */))
const _46adef9b = () => interopDefault(import('../pages/users/_id/communications/index/video_call.vue' /* webpackChunkName: "pages/users/_id/communications/index/video_call" */))
const _75dd48c1 = () => interopDefault(import('../pages/users/_id/dashboard/index.vue' /* webpackChunkName: "pages/users/_id/dashboard/index" */))
const _0ab73f6c = () => interopDefault(import('../pages/users/_id/devices/index.vue' /* webpackChunkName: "pages/users/_id/devices/index" */))
const _e2525836 = () => interopDefault(import('../pages/users/_id/documents/index.vue' /* webpackChunkName: "pages/users/_id/documents/index" */))
const _7f684e44 = () => interopDefault(import('../pages/users/_id/more/index.vue' /* webpackChunkName: "pages/users/_id/more/index" */))
const _e77c1ca4 = () => interopDefault(import('../pages/users/_id/notes/index.vue' /* webpackChunkName: "pages/users/_id/notes/index" */))
const _87803790 = () => interopDefault(import('../pages/users/_id/oasis/index.vue' /* webpackChunkName: "pages/users/_id/oasis/index" */))
const _71ebb1fc = () => interopDefault(import('../pages/users/_id/overview/index.vue' /* webpackChunkName: "pages/users/_id/overview/index" */))
const _b73d7c8e = () => interopDefault(import('../pages/users/_id/overview/index/fitbit.vue' /* webpackChunkName: "pages/users/_id/overview/index/fitbit" */))
const _a3753ef8 = () => interopDefault(import('../pages/users/_id/overview/index/lab-vitals.vue' /* webpackChunkName: "pages/users/_id/overview/index/lab-vitals" */))
const _60067d12 = () => interopDefault(import('../pages/users/_id/overview/index/medications.vue' /* webpackChunkName: "pages/users/_id/overview/index/medications" */))
const _8dbaea78 = () => interopDefault(import('../pages/users/_id/overview/index/vitals.vue' /* webpackChunkName: "pages/users/_id/overview/index/vitals" */))
const _88ea6d78 = () => interopDefault(import('../pages/users/_id/playground/index.vue' /* webpackChunkName: "pages/users/_id/playground/index" */))
const _0112d2ac = () => interopDefault(import('../pages/users/_id/reports/index.vue' /* webpackChunkName: "pages/users/_id/reports/index" */))
const _be51445e = () => interopDefault(import('../pages/users/_id/rewards/index.vue' /* webpackChunkName: "pages/users/_id/rewards/index" */))
const _01b20897 = () => interopDefault(import('../pages/users/_id/timers/index.vue' /* webpackChunkName: "pages/users/_id/timers/index" */))
const _fdbc825e = () => interopDefault(import('../pages/users/_id/tracks/index.vue' /* webpackChunkName: "pages/users/_id/tracks/index" */))
const _6092754d = () => interopDefault(import('../pages/users/_id/cannahealrx/notes.vue' /* webpackChunkName: "pages/users/_id/cannahealrx/notes" */))
const _a99302c4 = () => interopDefault(import('../pages/users/_id/dental/appointments/index.vue' /* webpackChunkName: "pages/users/_id/dental/appointments/index" */))
const _62f964aa = () => interopDefault(import('../pages/users/_id/dental/notes.vue' /* webpackChunkName: "pages/users/_id/dental/notes" */))
const _a5f7beba = () => interopDefault(import('../pages/users/_id/dental/overview/index.vue' /* webpackChunkName: "pages/users/_id/dental/overview/index" */))
const _6b7ad480 = () => interopDefault(import('../pages/users/_id/dental/overview/index/achievements.vue' /* webpackChunkName: "pages/users/_id/dental/overview/index/achievements" */))
const _c07414bc = () => interopDefault(import('../pages/users/_id/dental/overview/index/tasks.vue' /* webpackChunkName: "pages/users/_id/dental/overview/index/tasks" */))
const _15d2f284 = () => interopDefault(import('../pages/users/_id/oasis/allergies.vue' /* webpackChunkName: "pages/users/_id/oasis/allergies" */))
const _05cdc4cf = () => interopDefault(import('../pages/users/_id/oasis/assessment/index.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/index" */))
const _7fd2d814 = () => interopDefault(import('../pages/users/_id/oasis/edit.vue' /* webpackChunkName: "pages/users/_id/oasis/edit" */))
const _66b750f4 = () => interopDefault(import('../pages/users/_id/oasis/lab-data.vue' /* webpackChunkName: "pages/users/_id/oasis/lab-data" */))
const _5a4b817d = () => interopDefault(import('../pages/users/_id/oasis/medical-claims.vue' /* webpackChunkName: "pages/users/_id/oasis/medical-claims" */))
const _751ee12c = () => interopDefault(import('../pages/users/_id/oasis/medications.vue' /* webpackChunkName: "pages/users/_id/oasis/medications" */))
const _57947aa7 = () => interopDefault(import('../pages/users/_id/oasis/notes.vue' /* webpackChunkName: "pages/users/_id/oasis/notes" */))
const _2521a93d = () => interopDefault(import('../pages/users/_id/oasis/pharmacy.vue' /* webpackChunkName: "pages/users/_id/oasis/pharmacy" */))
const _09c37508 = () => interopDefault(import('../pages/users/_id/oasis/providers.vue' /* webpackChunkName: "pages/users/_id/oasis/providers" */))
const _2b6b33b0 = () => interopDefault(import('../pages/users/_id/oasis/records.vue' /* webpackChunkName: "pages/users/_id/oasis/records" */))
const _3e7740f8 = () => interopDefault(import('../pages/users/_id/oasis/rx-claims.vue' /* webpackChunkName: "pages/users/_id/oasis/rx-claims" */))
const _68bbddab = () => interopDefault(import('../pages/users/_id/oasis/vaccination.vue' /* webpackChunkName: "pages/users/_id/oasis/vaccination" */))
const _16de4874 = () => interopDefault(import('../pages/users/_id/playground/demo-data.vue' /* webpackChunkName: "pages/users/_id/playground/demo-data" */))
const _1fc0d318 = () => interopDefault(import('../pages/users/_id/playground/magic-link.vue' /* webpackChunkName: "pages/users/_id/playground/magic-link" */))
const _6bf51a5c = () => interopDefault(import('../pages/users/_id/syracuse/overview/index.vue' /* webpackChunkName: "pages/users/_id/syracuse/overview/index" */))
const _24af45c4 = () => interopDefault(import('../pages/users/_id/syracuse/PatientCardSyracuse.vue' /* webpackChunkName: "pages/users/_id/syracuse/PatientCardSyracuse" */))
const _48c1c062 = () => interopDefault(import('../pages/users/_id/oasis/assessment/create.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/create" */))
const _3715950a = () => interopDefault(import('../pages/users/_id/oasis/assessment/oasisAssessmentSvg.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/oasisAssessmentSvg" */))
const _5a4627da = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/index.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/index" */))
const _04a294df = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/allergies.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/allergies" */))
const _93d2976a = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/care_plans.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/care_plans" */))
const _7a92ea14 = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/finalize_cmr.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/finalize_cmr" */))
const _3ee10705 = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/medications.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/medications" */))
const _4ab5a277 = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/patient_info.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/patient_info" */))
const _6709f49d = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/vaccinations.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/vaccinations" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _41602094 = () => interopDefault(import('../pages/index/_name/index.vue' /* webpackChunkName: "pages/index/_name/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _25702d7e,
    name: "404"
  }, {
    path: "/admin",
    component: _5906a1d0,
    name: "admin",
    children: [{
      path: "admin_users",
      component: _4f8585e4,
      name: "admin-index-admin_users"
    }, {
      path: "apps",
      component: _cb0cff08,
      name: "admin-index-apps"
    }, {
      path: "dispensaries",
      component: _18de23f0,
      name: "admin-index-dispensaries"
    }, {
      path: "groups",
      component: _49779d9e,
      name: "admin-index-groups"
    }, {
      path: "import_patients",
      component: _4cd193f1,
      name: "admin-index-import_patients"
    }, {
      path: "measurement_durations",
      component: _c750b736,
      name: "admin-index-measurement_durations"
    }, {
      path: "onboard_medications",
      component: _e6d39896,
      name: "admin-index-onboard_medications"
    }, {
      path: "organizations",
      component: _79dbdfa8,
      name: "admin-index-organizations"
    }, {
      path: "payment_coupons",
      component: _7e1aa5c0,
      name: "admin-index-payment_coupons"
    }, {
      path: "provider_compensation",
      component: _1b2b5374,
      name: "admin-index-provider_compensation"
    }, {
      path: "referal_coupons",
      component: _76b6d485,
      name: "admin-index-referal_coupons"
    }, {
      path: "rewards",
      component: _f092fd20,
      name: "admin-index-rewards"
    }, {
      path: "roles",
      component: _10d807f4,
      name: "admin-index-roles"
    }, {
      path: "services",
      component: _28060030,
      name: "admin-index-services"
    }, {
      path: "specialists",
      component: _2539df6b,
      name: "admin-index-specialists"
    }, {
      path: "states",
      component: _1a829f68,
      name: "admin-index-states"
    }, {
      path: "tracks",
      component: _78102b52,
      name: "admin-index-tracks"
    }, {
      path: "users",
      component: _24528394,
      name: "admin-index-users"
    }, {
      path: "website_hours",
      component: _679be098,
      name: "admin-index-website_hours"
    }, {
      path: "documents/shared",
      component: _fe99333a,
      name: "admin-index-documents-shared"
    }, {
      path: "groups/create",
      component: _0d3060e0,
      name: "admin-index-groups-create"
    }, {
      path: "library/topics",
      component: _1c88e4c2,
      name: "admin-index-library-topics"
    }, {
      path: "tracks/alert/:alertId",
      component: _6082091e,
      name: "admin-index-tracks-alert-alertId"
    }, {
      path: "library/topics/:id?/articles",
      component: _e9997f96,
      name: "admin-index-library-topics-id-articles"
    }, {
      path: "groups/:id",
      component: _4c268cee,
      name: "admin-index-groups-id"
    }, {
      path: "tracks/:id",
      component: _6d416586,
      name: "admin-index-tracks-id"
    }, {
      path: "users/:userId",
      component: _0799212c,
      name: "admin-index-users-userId"
    }, {
      path: "apps/:id?/edit",
      component: _41fd5d05,
      name: "admin-index-apps-id-edit"
    }, {
      path: "organizations/:id?/settings",
      component: _7c03f76e,
      name: "admin-index-organizations-id-settings"
    }]
  }, {
    path: "/alerts",
    component: _0e06e978,
    name: "alerts"
  }, {
    path: "/amdiabetes",
    component: _eb8ef848,
    name: "amdiabetes"
  }, {
    path: "/appointments",
    component: _45e13ba1,
    name: "appointments"
  }, {
    path: "/cannahealrx",
    component: _5b12e78c,
    name: "cannahealrx"
  }, {
    path: "/chats",
    component: _1d9ff4e4,
    name: "chats"
  }, {
    path: "/civiclee",
    component: _f476fb62,
    name: "civiclee"
  }, {
    path: "/dashboard",
    component: _50d99d1d,
    name: "dashboard"
  }, {
    path: "/documents",
    component: _69d32841,
    name: "documents"
  }, {
    path: "/general_chat",
    component: _e7419d08,
    name: "general_chat"
  }, {
    path: "/goodcannanow",
    component: _b702abc6,
    name: "goodcannanow"
  }, {
    path: "/inventory",
    component: _7f68f1e5,
    name: "inventory"
  }, {
    path: "/magic-link",
    component: _787257f2,
    name: "magic-link"
  }, {
    path: "/meeting",
    component: _32a91b84,
    name: "meeting"
  }, {
    path: "/oasis",
    component: _d12672d8,
    name: "oasis"
  }, {
    path: "/organization_appointments",
    component: _b6b2606e,
    name: "organization_appointments"
  }, {
    path: "/overview",
    component: _5cdd46e3,
    name: "overview"
  }, {
    path: "/patient_filters",
    component: _3b7c748a,
    name: "patient_filters"
  }, {
    path: "/patients",
    component: _7166697b,
    name: "patients"
  }, {
    path: "/profile",
    component: _ef592d1c,
    name: "profile"
  }, {
    path: "/providers",
    component: _49c3b1ab,
    name: "providers",
    children: [{
      path: ":id",
      component: _1af3004e,
      name: "providers-index-id"
    }]
  }, {
    path: "/readings",
    component: _3cf248d8,
    name: "readings"
  }, {
    path: "/reminders",
    component: _d585e6ec,
    name: "reminders"
  }, {
    path: "/reports",
    component: _76dee3f0,
    name: "reports",
    children: [{
      path: "conversation_reports",
      component: _58bd2e4c,
      name: "reports-index-conversation_reports"
    }, {
      path: "multiple_patients",
      component: _4cd4d019,
      name: "reports-index-multiple_patients",
      children: [{
        path: "ccm",
        component: _d82fd3d2,
        name: "reports-index-multiple_patients-index-ccm"
      }, {
        path: "general",
        component: _78891f1c,
        name: "reports-index-multiple_patients-index-general"
      }]
    }, {
      path: "single_patients",
      component: _d1f839c4,
      name: "reports-index-single_patients"
    }]
  }, {
    path: "/team_monitoring",
    component: _a159c01a,
    name: "team_monitoring"
  }, {
    path: "/testPage",
    component: _148037ab,
    name: "testPage"
  }, {
    path: "/auth/forbidden",
    component: _b1616888,
    name: "auth-forbidden"
  }, {
    path: "/auth/forgot-password",
    component: _2fd260f0,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _1570f4ac,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _3482ad52,
    name: "auth-logout"
  }, {
    path: "/auth/register",
    component: _3bcc56a0,
    name: "auth-register"
  }, {
    path: "/auth/success-fitbit",
    component: _04e0c59a,
    name: "auth-success-fitbit"
  }, {
    path: "/auth/two_factor",
    component: _93d1f99c,
    name: "auth-two_factor"
  }, {
    path: "/cannahealrx/appointments",
    component: _c9478508,
    name: "cannahealrx-appointments"
  }, {
    path: "/cannahealrx/approved",
    component: _57072c02,
    name: "cannahealrx-approved"
  }, {
    path: "/cannahealrx/book",
    component: _eff5259e,
    name: "cannahealrx-book"
  }, {
    path: "/cannahealrx/booking",
    component: _120a1c41,
    name: "cannahealrx-booking"
  }, {
    path: "/cannahealrx/no_show",
    component: _5595ecba,
    name: "cannahealrx-no_show"
  }, {
    path: "/cannahealrx/patients",
    component: _e4aa1454,
    name: "cannahealrx-patients"
  }, {
    path: "/cannahealrx/rejected",
    component: _67e7dc46,
    name: "cannahealrx-rejected"
  }, {
    path: "/civiclee/indexOld",
    component: _6e8e8e08,
    name: "civiclee-indexOld"
  }, {
    path: "/civiclee/landing",
    component: _333103d4,
    name: "civiclee-landing"
  }, {
    path: "/civiclee/landinggpt",
    component: _1c6569f2,
    name: "civiclee-landinggpt"
  }, {
    path: "/civiclee/landingOld",
    component: _16fa3b3a,
    name: "civiclee-landingOld"
  }, {
    path: "/civiclee/members",
    component: _0f34e04e,
    name: "civiclee-members"
  }, {
    path: "/civiclee/onboarding",
    component: _cb47ab24,
    name: "civiclee-onboarding"
  }, {
    path: "/civiclee/success",
    component: _6c159880,
    name: "civiclee-success"
  }, {
    path: "/dashboard/all_patients",
    component: _65297571,
    name: "dashboard-all_patients"
  }, {
    path: "/dashboard/call_list",
    component: _38154e2a,
    name: "dashboard-call_list"
  }, {
    path: "/dashboard/my_patients",
    component: _de026468,
    name: "dashboard-my_patients"
  }, {
    path: "/dashboard/onboarding",
    component: _31eda060,
    name: "dashboard-onboarding"
  }, {
    path: "/dental/appointments",
    component: _2da3aeb7,
    name: "dental-appointments"
  }, {
    path: "/dental/library_de",
    component: _18931a70,
    name: "dental-library_de"
  }, {
    path: "/dental/library_en",
    component: _142bc220,
    name: "dental-library_en"
  }, {
    path: "/dental/onboarding",
    component: _1fe31f5e,
    name: "dental-onboarding"
  }, {
    path: "/halee/landing",
    component: _3f92cf81,
    name: "halee-landing"
  }, {
    path: "/inventory/upload",
    component: _354ff7fe,
    name: "inventory-upload"
  }, {
    path: "/magic-link/expired",
    component: _528204cc,
    name: "magic-link-expired"
  }, {
    path: "/monitoring/rpm",
    component: _2cbe87dc,
    name: "monitoring-rpm"
  }, {
    path: "/oasis/all_patients",
    component: _7d12081a,
    name: "oasis-all_patients"
  }, {
    path: "/oasis/my_patients",
    component: _58567bfa,
    name: "oasis-my_patients"
  }, {
    path: "/oasis/onboarding",
    component: _5e066d6e,
    name: "oasis-onboarding"
  }, {
    path: "/oasis/review",
    component: _8981c1f4,
    name: "oasis-review"
  }, {
    path: "/reports/loading",
    component: _7898dc1c,
    name: "reports-loading"
  }, {
    path: "/auth/patient/delete",
    component: _2fb96a45,
    name: "auth-patient-delete"
  }, {
    path: "/charts/apex/data",
    component: _6247ea08,
    name: "charts-apex-data"
  }, {
    path: "/charts/chartjs/DonutChart",
    component: _a8504c38,
    name: "charts-chartjs-DonutChart"
  }, {
    path: "/auth/patient/delete/success",
    component: _6b601394,
    name: "auth-patient-delete-success"
  }, {
    path: "/auth/patient/delete/:identifier/verify",
    component: _2be40686,
    name: "auth-patient-delete-identifier-verify"
  }, {
    path: "/auth/reset/:id?",
    component: _55441786,
    name: "auth-reset-id"
  }, {
    path: "/auth/two_factor/:id",
    component: _acdfabcc,
    name: "auth-two_factor-id"
  }, {
    path: "/civiclee/users/:id",
    component: _3e363b9a,
    name: "civiclee-users-id"
  }, {
    path: "/dashboard/merge_patient/:id",
    component: _1ed90c12,
    name: "dashboard-merge_patient-id"
  }, {
    path: "/civiclee/users/:id?/overview",
    component: _164f03ab,
    name: "civiclee-users-id-overview"
  }, {
    path: "/dashboard/merge_patient/:id?/:cid",
    component: _4caf8626,
    name: "dashboard-merge_patient-id-cid"
  }, {
    path: "/amdiabetes/:slug",
    component: _eefc3ad8,
    name: "amdiabetes-slug"
  }, {
    path: "/cannahealrx/:status",
    component: _4d8bc4f9,
    name: "cannahealrx-status"
  }, {
    path: "/magic-link/:code?",
    component: _74dcf5fe,
    name: "magic-link-code"
  }, {
    path: "/meeting/:id",
    component: _9e7e5722,
    name: "meeting-id"
  }, {
    path: "/monitoring/:id",
    component: _28fdb9c0,
    name: "monitoring-id"
  }, {
    path: "/onboarding/:id",
    component: _0ea587b3,
    name: "onboarding-id",
    children: [{
      path: "address",
      component: _4b961b18,
      name: "onboarding-id-index-address"
    }, {
      path: "care_plan",
      component: _74208e3b,
      name: "onboarding-id-index-care_plan"
    }, {
      path: "care_provider",
      component: _5bbb4ffa,
      name: "onboarding-id-index-care_provider"
    }, {
      path: "contact",
      component: _bfc4a578,
      name: "onboarding-id-index-contact"
    }, {
      path: "insurance",
      component: _bf646004,
      name: "onboarding-id-index-insurance"
    }, {
      path: "patient_information",
      component: _67e4bd16,
      name: "onboarding-id-index-patient_information"
    }, {
      path: "specialists",
      component: _4998b0f4,
      name: "onboarding-id-index-specialists"
    }, {
      path: "tasks",
      component: _74e1d0b2,
      name: "onboarding-id-index-tasks"
    }]
  }, {
    path: "/patient_filters/:id",
    component: _02d78b16,
    name: "patient_filters-id"
  }, {
    path: "/pg/:token",
    component: _4477091b,
    name: "pg-token"
  }, {
    path: "/readings/:rid",
    component: _60d03d7c,
    name: "readings-rid"
  }, {
    path: "/team_monitoring/:id",
    component: _7602f9ca,
    name: "team_monitoring-id"
  }, {
    path: "/users/:id",
    component: _e0a15e08,
    name: "users-id"
  }, {
    path: "/meeting/:id/ended",
    component: _5e954257,
    name: "meeting-id-ended"
  }, {
    path: "/profile/:id/schedule",
    component: _513a3fdc,
    name: "profile-id-schedule"
  }, {
    path: "/users/:id?/achievements",
    component: _34ae2fed,
    name: "users-id-achievements"
  }, {
    path: "/users/:id?/alerts",
    component: _6e8d2220,
    name: "users-id-alerts"
  }, {
    path: "/users/:id?/appointments",
    component: _a7f78506,
    name: "users-id-appointments"
  }, {
    path: "/users/:id?/attachments",
    component: _a1868b46,
    name: "users-id-attachments",
    children: [{
      path: "documents",
      component: _23bac306,
      name: "users-id-attachments-index-documents"
    }, {
      path: "images",
      component: _3393ab1a,
      name: "users-id-attachments-index-images"
    }, {
      path: "videos",
      component: _292433fa,
      name: "users-id-attachments-index-videos"
    }]
  }, {
    path: "/users/:id?/badges",
    component: _426ca50e,
    name: "users-id-badges"
  }, {
    path: "/users/:id?/cannahealrx",
    component: _75844244,
    name: "users-id-cannahealrx"
  }, {
    path: "/users/:id?/communication",
    component: _1932aafa,
    name: "users-id-communication"
  }, {
    path: "/users/:id?/communications",
    component: _f4c04534,
    name: "users-id-communications",
    children: [{
      path: "phone-call",
      component: _1d6879d6,
      name: "users-id-communications-index-phone-call"
    }, {
      path: "text",
      component: _e0573f34,
      name: "users-id-communications-index-text"
    }, {
      path: "video_call",
      component: _46adef9b,
      name: "users-id-communications-index-video_call"
    }]
  }, {
    path: "/users/:id?/dashboard",
    component: _75dd48c1,
    name: "users-id-dashboard"
  }, {
    path: "/users/:id?/devices",
    component: _0ab73f6c,
    name: "users-id-devices"
  }, {
    path: "/users/:id?/documents",
    component: _e2525836,
    name: "users-id-documents"
  }, {
    path: "/users/:id?/more",
    component: _7f684e44,
    name: "users-id-more"
  }, {
    path: "/users/:id?/notes",
    component: _e77c1ca4,
    name: "users-id-notes"
  }, {
    path: "/users/:id?/oasis",
    component: _87803790,
    name: "users-id-oasis"
  }, {
    path: "/users/:id?/overview",
    component: _71ebb1fc,
    name: "users-id-overview",
    children: [{
      path: "fitbit",
      component: _b73d7c8e,
      name: "users-id-overview-index-fitbit"
    }, {
      path: "lab-vitals",
      component: _a3753ef8,
      name: "users-id-overview-index-lab-vitals"
    }, {
      path: "medications",
      component: _60067d12,
      name: "users-id-overview-index-medications"
    }, {
      path: "vitals",
      component: _8dbaea78,
      name: "users-id-overview-index-vitals"
    }]
  }, {
    path: "/users/:id?/playground",
    component: _88ea6d78,
    name: "users-id-playground"
  }, {
    path: "/users/:id?/reports",
    component: _0112d2ac,
    name: "users-id-reports"
  }, {
    path: "/users/:id?/rewards",
    component: _be51445e,
    name: "users-id-rewards"
  }, {
    path: "/users/:id?/timers",
    component: _01b20897,
    name: "users-id-timers"
  }, {
    path: "/users/:id?/tracks",
    component: _fdbc825e,
    name: "users-id-tracks"
  }, {
    path: "/users/:id?/cannahealrx/notes",
    component: _6092754d,
    name: "users-id-cannahealrx-notes"
  }, {
    path: "/users/:id?/dental/appointments",
    component: _a99302c4,
    name: "users-id-dental-appointments"
  }, {
    path: "/users/:id?/dental/notes",
    component: _62f964aa,
    name: "users-id-dental-notes"
  }, {
    path: "/users/:id?/dental/overview",
    component: _a5f7beba,
    name: "users-id-dental-overview",
    children: [{
      path: "achievements",
      component: _6b7ad480,
      name: "users-id-dental-overview-index-achievements"
    }, {
      path: "tasks",
      component: _c07414bc,
      name: "users-id-dental-overview-index-tasks"
    }]
  }, {
    path: "/users/:id?/oasis/allergies",
    component: _15d2f284,
    name: "users-id-oasis-allergies"
  }, {
    path: "/users/:id?/oasis/assessment",
    component: _05cdc4cf,
    name: "users-id-oasis-assessment"
  }, {
    path: "/users/:id?/oasis/edit",
    component: _7fd2d814,
    name: "users-id-oasis-edit"
  }, {
    path: "/users/:id?/oasis/lab-data",
    component: _66b750f4,
    name: "users-id-oasis-lab-data"
  }, {
    path: "/users/:id?/oasis/medical-claims",
    component: _5a4b817d,
    name: "users-id-oasis-medical-claims"
  }, {
    path: "/users/:id?/oasis/medications",
    component: _751ee12c,
    name: "users-id-oasis-medications"
  }, {
    path: "/users/:id?/oasis/notes",
    component: _57947aa7,
    name: "users-id-oasis-notes"
  }, {
    path: "/users/:id?/oasis/pharmacy",
    component: _2521a93d,
    name: "users-id-oasis-pharmacy"
  }, {
    path: "/users/:id?/oasis/providers",
    component: _09c37508,
    name: "users-id-oasis-providers"
  }, {
    path: "/users/:id?/oasis/records",
    component: _2b6b33b0,
    name: "users-id-oasis-records"
  }, {
    path: "/users/:id?/oasis/rx-claims",
    component: _3e7740f8,
    name: "users-id-oasis-rx-claims"
  }, {
    path: "/users/:id?/oasis/vaccination",
    component: _68bbddab,
    name: "users-id-oasis-vaccination"
  }, {
    path: "/users/:id?/playground/demo-data",
    component: _16de4874,
    name: "users-id-playground-demo-data"
  }, {
    path: "/users/:id?/playground/magic-link",
    component: _1fc0d318,
    name: "users-id-playground-magic-link"
  }, {
    path: "/users/:id?/syracuse/overview",
    component: _6bf51a5c,
    name: "users-id-syracuse-overview"
  }, {
    path: "/users/:id?/syracuse/PatientCardSyracuse",
    component: _24af45c4,
    name: "users-id-syracuse-PatientCardSyracuse"
  }, {
    path: "/users/:id?/oasis/assessment/create",
    component: _48c1c062,
    name: "users-id-oasis-assessment-create"
  }, {
    path: "/users/:id?/oasis/assessment/oasisAssessmentSvg",
    component: _3715950a,
    name: "users-id-oasis-assessment-oasisAssessmentSvg"
  }, {
    path: "/users/:id?/oasis/assessment/:aid",
    component: _5a4627da,
    name: "users-id-oasis-assessment-aid"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/allergies",
    component: _04a294df,
    name: "users-id-oasis-assessment-aid-allergies"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/care_plans",
    component: _93d2976a,
    name: "users-id-oasis-assessment-aid-care_plans"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/finalize_cmr",
    component: _7a92ea14,
    name: "users-id-oasis-assessment-aid-finalize_cmr"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/medications",
    component: _3ee10705,
    name: "users-id-oasis-assessment-aid-medications"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/patient_info",
    component: _4ab5a277,
    name: "users-id-oasis-assessment-aid-patient_info"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/vaccinations",
    component: _6709f49d,
    name: "users-id-oasis-assessment-aid-vaccinations"
  }, {
    path: "/",
    component: _0547af50,
    name: "index",
    children: [{
      path: ":name",
      component: _41602094,
      name: "index-name"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
