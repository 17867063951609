export const state = () => ({
  groupChats: [],
  directChats: [],
  selectedChat: null,
  unreadChats: 0,
});

export const mutations = {
  SET_SELECTED_CHAT(state, data) {
    let findGroup = state.groupChats.findIndex((e) => {
      return e.id === data.id;
    });
    if (findGroup !== -1) {
      state.groupChats[findGroup].unread_count = 0;
    }

    let findDirect = state.directChats.findIndex((e) => {
      return e.id === data.id;
    });
    if (findDirect !== -1) {
      state.directChats[findDirect].unread_count = 0;
    }
    state.selectedChat = data;
  },
  SET_NEW_LAST_MESSAGE(state, data) {
    let chats = [...state.groupChats, ...state.directChats];

    const targetIndex = chats.findIndex((e) => e.id === state.selectedChat.id);
    if (targetIndex !== -1) {
      // Remove the found object from its current position
      const [targetChat] = chats.splice(targetIndex, 1);

      // Update the last_message
      targetChat.conversation.last_message = data;

      // Place the target object at the beginning of the array
      chats.unshift(targetChat);
    }

    state.groupChats = chats.filter((e) => {
      return !e.direct_message;
    });
    state.directChats = chats.filter((e) => {
      return e.direct_message;
    });
  },
  SET_READ_CHATS(state, data = 0) {
    state.selectedChat.unread_count = data;
  },
  MUTE_CONVO(state, data) {
    state.selectedChat.settings.is_muted = data;
  },
  SET_UNREAD_CHATS(state, data) {
    state.unreadChats += 1;
  },
  SET_INITIAL_UNREAD_CHATS(state, data) {
    state.unreadChats = data;
  },
  //when no direct chat in communication page sets a dummy direct chat
  SET_DUMMY_DIRECT_CHAT(state, data) {
    let dummyData = {
      id: "New",
      data: "[]",
      direct_message: 1,
      conversation_id: "New",
      user: data?.patient,
      conversation: {
        direct_message: 1,
        id: "New",
        data: "[]",
        settings: {},
        participants: [
          {
            messageable_type: "User",
            messageable_id: data?.user?.id,

            messageable: data?.user,
            settings: {},
            user: {
              deleted_at: null,
              display_name: data?.user?.display_name,
              id: data?.user?.id,
            },
          },
          {
            messageable_type: "Patient",
            messageable_id: data?.patient?.id,
            messageable: data?.patient,
            settings: {},
            user: {
              deleted_at: null,
              display_name: data?.patient?.display_name,
              id: data?.patient?.id,
            },
          },
        ],
      },
    };

    if (!state.directChats.length) state.directChats.push(dummyData);
  },
  JOIN_GROUP(state, data) {
    state.selectedChat.conversation.participants.push({
      user: data,
      messageable_type: "User",
    });
    if (state.selectedChat.settings && state.selectedChat.settings.left_at) {
      state.selectedChat.settings.left_at = null;
    }
  },
  LEAVE_GROUP(state, data) {
    state.selectedChat.conversation.participants =
      state.selectedChat.conversation.participants.filter((e) => {
        return e?.user?.id !== data;
      });
    state.selectedChat.settings.left_at = new Date();
  },
  SET_SEARCHED_CHATS(state, data) {
    const chats = data.map((e) => {
      return {
        ...e,
        settings: e?.settings || {},
      };
    });
    console.log(chats);
    state.groupChats = chats.filter((e) => {
      return !e.direct_message;
    });
    state.directChats = chats.filter((e) => {
      return e.direct_message;
    });
  },
  SET_CHATS(state, data) {
    let currentChats = data
      .filter((e) => {
        let userDeleted = e.conversation.participants.some((e) => {
          return !e.user;
        });
        if (!userDeleted) return e;
      })
      .map((e) => {
        return { ...e, settings: e.settings ? e.settings : {} };
      });
    state.groupChats = currentChats.filter((e) => {
      return e.direct_message === 0;
    });
    state.directChats = currentChats.filter((e) => {
      return e.direct_message === 1;
    });
  },
};

export const getters = {
  getChatKey: (state) => (key) => {
    return state[key];
  },
};
